<template>
    <div class="action-accept-introduce">
        <template v-if="isAgentUser">
            <div class="success-message m-b-12">
                <div class="top">
                    <img class="brand" src="@/assets/images/logo_text.svg" />
                </div>
                <div class="p-12">
                    <p class="title m-b-12" v-html="$translate('ACTION_ACCEPT_INTRODUCE_AGENT_TITLE')" />
                    <p class="body" v-html="$translate('ACTION_ACCEPT_INTRODUCE_AGENT_BODY')" />
                </div>
            </div>
        </template>

        <template v-else>
            <div class="success-message m-b-12">
                <div class="top">
                    <img class="brand" src="@/assets/images/logo_text.svg" />
                </div>
                <div class="p-12">
                    <p class="title m-b-12" v-html="$translate('ACTION_CONTACT_DONE_TITLE')" />
                    <p class="body" v-html="body" />
                </div>
            </div>
        </template>

        <div v-if="isKakaoId" class="contact" @click="onClickContact">
            <div class="icon-wrapper">
                <img src="@/assets/images/chat/purple_kakaotalk.svg" />
            </div>
            <span class="f-bold">{{ name }}</span
            >님 카카오톡ID
        </div>

        <div v-else class="contact" @click="onClickContact">
            <div class="icon-wrapper">
                <i class="f-18 material-icons">call</i>
            </div>
            <span class="f-bold">{{ name }}</span
            >님 연락처
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionAcceptIntroduce',
    props: {
        message: Object,
    },
    computed: {
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        chat() {
            return this.$store.getters.chat
        },
        isAgentUser() {
            return this.chat.introduce.user_id === this.$store.getters.me.id
        },
        isKakaoId() {
            if (this.isAgentUser) {
                return /[a-zA-Z]/g.test(this.content.user_phone_number)
            }

            return /[a-zA-Z]/g.test(this.content.phone_number)
        },
        body() {
            const nameOrNick = this.$nameOrNick(this.chat.user)
            return this.$translate('ACTION_ACCEPT_INTRODUCE_USER_BODY')
                .replace(/%s/, nameOrNick)
                .replace(/%s/, this.content.name)
        },
        name() {
            if (this.isAgentUser) {
                return this.$nameOrNick(this.chat.user)
            }

            return this.content.name
        },
    },
    methods: {
        checkStatus() {
            if (!this.isAgentUser) return

            if (
                !['success', 'meeting', 'refund', 'refund_accept', 'refund_reject'].includes(
                    this.chat.introduce.request.accept_status,
                )
            ) {
                const chat = { ...this.chat }
                chat.introduce.request.accept_status = 'success'
                chat.expire_at = null
                this.$store.commit('updateChatAndChats', chat)
            }
        },
        onClickContact() {
            let body = ''

            if (this.isAgentUser) {
                body = `${this.name}, ${this.content.user_phone_number}`
            } else {
                body = `${this.name}, ${this.content.phone_number}`
            }

            this.$modal.custom({
                component: 'ModalContact',
                options: {
                    body: this.$options.filters.translate(body),
                },
            })
        },
    },
    mounted() {
        this.checkStatus()
    },
}
</script>

<style lang="scss" scoped>
.action-accept-introduce {
    line-height: normal;

    .success-message {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05), 0 1px 6px 0 rgba(84, 72, 203, 0.05);
        overflow: hidden;

        .top {
            background: $purple-primary-grad;
            height: 20px;
            padding: 0 12px;
            justify-content: flex-end;

            @include items-center;
        }

        .title {
            font-size: 16px;
            color: black;

            @include f-medium;
        }

        .body {
            font-size: 14px;
        }

        .brand {
            width: 80px;
        }
    }

    .contact {
        padding: 10px 20px 10px 12px;
        border-radius: 12px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06), 0 1px 6px 0 rgba(84, 72, 203, 0.1);
        background-color: $purple-primary;
        color: white;
        display: inline-flex;
        align-items: center;

        .icon-wrapper {
            width: 24px;
            height: 24px;
            border-radius: 8px;
            background-color: white;
            margin-right: 10px;

            @include center;

            .material-icons {
                color: $purple-primary;
            }
        }
    }
}
</style>
